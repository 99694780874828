import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/global/seo';
import Layout from '../components/global/layout/Layout';
import Testimonial from '../components/global/Testimonial/Testimonial';
import InfoBox from '../components/global/info-box/InfoBox';
import WrapperOne from '../components/global/wrappers/WrapperOne';
import CTA from '../components/global/CTA/CTA';
import VerticalSlider from '../components/global/ImageSlider/VerticalSlider';
import { Container } from '../components/global/customers/styles';
import SectionTextBox from '../components/global/section-textbox/SectionTextBox';
import Snippet from '../components/global/snippet/Snippet';
import { sliderProps } from '../pages/bot-builder';
import * as S from '../components/demand-generation/styles';
import { ImageHolder } from '../components/home-page/about-chatbots/styles';
import { SliderWrapper } from '../components/customer-support/styles';
import { SectionWrapper } from '../components/mid-market/styles';
import {
	TestimonialWrapper,
	SliderSectionWrapper,
	HeroWrapper,
} from '../components/small-business/styles';

const SmallBusiness = ({ data }) => {
	const {
		seoTitle,
		seoDescription,
		seoArticle,
		seoImage,
		seoNoIndex,
		seoJsonSchema,
		section1Image,
		section1Button,
		section2Title,
		section2Subtitle,
		section2Images,
		section3Title,
		section3Subtitle,
		section3Images,
		testimonial,
		section5Image,
		ctaTitle,
	} = data.contentfulSmallBusiness;
	return (
		<>
			<Layout>
				<SEO
					title={seoTitle}
					description={seoDescription}
					image={seoImage}
					article={seoArticle}
					noIndex={seoNoIndex}
					seoJsonSchema={seoJsonSchema}
				/>
				<WrapperOne>
					<HeroWrapper>
						<InfoBox
							image={section1Image}
							title={section1Image.title}
							subtitle={section1Image.description}
							buttonText={section1Button?.linkText}
							buttonLink={section1Button?.link}
							subHeading
							isHeroHeader
						/>
					</HeroWrapper>
				</WrapperOne>
				<Container>
					<SliderSectionWrapper>
						<SectionTextBox title={section2Title} subtitle={section2Subtitle} />
					</SliderSectionWrapper>
				</Container>
				<SliderWrapper>
					<VerticalSlider data={section2Images} {...sliderProps} />
				</SliderWrapper>
				<Container>
					<SectionTextBox
						title={section3Title}
						subtitle={section3Subtitle}
						largeHeading="true"
					/>
					<SectionWrapper>
						<ImageHolder>
							{section3Images.map(info => (
								<Snippet
									url={info.file.url}
									title={info.title}
									subtitle={info.description}
								/>
							))}
						</ImageHolder>
					</SectionWrapper>
				</Container>
				<TestimonialWrapper>
					<Testimonial title={testimonial.title} src={testimonial.embed_url} />
				</TestimonialWrapper>
				<WrapperOne>
					<InfoBox
						image={section5Image}
						title={section5Image.title}
						subtitle={section5Image.description}
						hideImage="true"
					/>
				</WrapperOne>
				<S.CTAWrapper>
					<Container>
						<CTA title={ctaTitle} />
					</Container>
				</S.CTAWrapper>
			</Layout>
		</>
	);
};

export const query = graphql`
	query {
		contentfulSmallBusiness {
			seoTitle
			seoDescription
			seoArticle
			seoImage {
				title
				file {
					url
					details {
						image {
							height
							width
						}
					}
				}
			}
			seoNoIndex
			seoJsonSchema {
				internal {
					content
				}
			}
			section1Image {
				file {
					url
					details {
						image {
							height
							width
						}
					}
				}
				title
				fluid(maxWidth: 1027, maxHeight: 687) {
					...GatsbyContentfulFluid
				}
				description
			}
			section1Button {
				link
				linkText
			}
			section2Title
			section2Subtitle
			section2Images {
				title
				description
				fluid(maxHeight: 500, maxWidth: 747) {
					...GatsbyContentfulFluid
				}
			}
			section3Title
			section3Subtitle
			section3Images {
				file {
					url
				}
				title
				description
			}
			testimonial {
				title
				embed_url
			}
			section5Image {
				title
				description
				fluid {
					...GatsbyContentfulFluid
				}
			}
			ctaTitle
		}
	}
`;

export default SmallBusiness;
